import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from './header.component';
import { LoadingPleaseWaitModule } from '../LoadingPleaseWait/LoadingPleaseWait.module';
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    MatTabsModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    LoadingPleaseWaitModule,
  ],
  declarations: [HeaderComponent],
  exports: [MatMenuModule, HeaderComponent],
})
export class HeaderModule {}
