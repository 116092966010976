import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-enter-new-pawwsord',
  templateUrl: './enter-new-password.component.html',
  styleUrls: ['./enter-new-passord.component.scss'],
})
export class EnterNewPasswordComponent implements OnInit {
  pass = '';
  passConf = '';
  cell = 0;
  passwordCreated = false;
  constructor(
    private router: Router,
    public http: HttpClient,
    private renderer: Renderer2,
    private storage: Storage
  ) {}

  ngOnInit() {
    this.storage.get('cell').then((res) => {
      if (res) {
        this.cell = res;
      }
    });
  }

  podtOtp(passw: string, passConf: string) {
    const output = document.getElementById('out_put');
    if (passw !== passConf) {
      return this.renderer.setProperty(
        output,
        'innerHTML',
        'Password does not match'
      );
    } else if (passw.length < 7 || passConf.length <= 7) {
      return this.renderer.setProperty(
        output,
        'innerHTML',
        'Password must be aleast 8 characters'
      );
    } else {
      const link = 'https://app.ubombosugar.com/api/passCreate';
      this.http
        .post<any>(link, {
          pass: passw,
          cell: this.cell,
        })
        .subscribe(
          (data) => {
            this.passwordCreated = true;
            if (data.message === 'Password created') {
              setTimeout(() => {
                this.router.navigate(['login']);
              }, 1200);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }
}
/* 3916 */
