import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from '../../Components/header/header.component';
import { CommentsComponent } from './comments.component';
import { BrowserModule } from '@angular/platform-browser';
import {BallonComponent} from './ballon/ballon.component';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
  ],
  declarations: [CommentsComponent,BallonComponent],
})
export class CommentsModule {}
