import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from '../../services/contentLoading.service';

@Component({
  selector: 'app-LoadingPleaseWait',
  templateUrl: './LoadingPleaseWait.component.html',
  styleUrls: ['./LoadingPleaseWait.component.scss'],
})
export class LoadingPleaseWaitComponent implements OnInit {
  @Input()
  text = '';
  @Input() NoLogo = 'false';
  constructor(public appLoading: LoadingService) {}

  ngOnInit() {}
}
