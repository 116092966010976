import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommentsModule } from '../comments/comments.module';
import { LoadingPleaseWaitComponent } from './LoadingPleaseWait.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [
    FormsModule,
    IonicModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatProgressBarModule,
    CommonModule,
  ],
  declarations: [LoadingPleaseWaitComponent],
  exports: [LoadingPleaseWaitComponent],
})
export class LoadingPleaseWaitModule {}
