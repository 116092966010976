import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  emailToUse = '';
  numberToCall = 0;
  constructor(private router: Router) {}

  ngOnInit() {}
  MakeEmail() {}
  navigateTo() {
    this.router.navigate(["tabs"]);

  }
}
