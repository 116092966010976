import { Component, OnInit, DoCheck, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { PoliciesApis } from '../../Services/policiesAPI.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {
  departments: any;
  constructor(
    public router: Router,
    private _location: Location,
    public http: HttpClient,
    public alertController: AlertController,
    public policiesAPIs: PoliciesApis,
    private storage: Storage
  ) {
    this.storage.get('ACCESS_TOKEN').then((res) => {
      this.policiesAPIs.getDepartments(res).subscribe(
        (data) => {
          this.departments = data.data;
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  ngOnInit() {}
  NavigateToDeptPolicies(deptName) {
    this.router.navigate(['News/Policies/DeptPolicy', deptName]);
  }

  searchDepartmentPolicies() {
    alert('Search Feature Coming Soon');
  }
  backClicked() {
    this._location.back();
  }
}
