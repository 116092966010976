import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { AlertController } from '@ionic/angular';
import { LoadingService } from '../../Services/contentLoading.service';
import { PoliciesApis } from '../../Services/policiesAPI.service';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-policies',
  templateUrl: './department-policies.component.html',
  styleUrls: ['./department-policies.component.scss'],
})
export class DepartmentPoliciesComponent implements OnInit {
  departmentName: string = '';
  search: string = '';
  clickedSearch = true;
  policies: any;
  noPolicies: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    public http: HttpClient,
    public alertController: AlertController,
    public appLoading: LoadingService,
    public policiesApis: PoliciesApis,
    public previewAnyFile: PreviewAnyFile,
    private storage: Storage
  ) {
    this.departmentName = this.route.snapshot.paramMap.get('deptName');
  }

  ngOnInit() {
    this.appLoading.setIsLoading(true);
    this.storage.get('ACCESS_TOKEN').then((res) => {
      this.policiesApis.getAllPolicies(this.departmentName, res).subscribe(
        (data) => {
          this.policies = data.data;
          if (this.policies.length === 0) {
            this.noPolicies === true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.appLoading.setIsLoading(false);
  }

  showAlert(alertMessage: string, title: string) {
    this.alertController
      .create({
        header: 'Alert',
        subHeader: title,
        message: alertMessage,
        buttons: ['OK'],
      })
      .then((res) => {
        res.present();
      });
  }
  async DownloadPolicies(file?) {
    //let fileJson = JSON.stringify(file);
    //this.showAlert(fileJson, 'fileObj');

    //console.log('Directory.Documents=', Directory.Documents);
    try {
      //Write  document to local storage
      const savedFile = await Filesystem.writeFile({
        path: file.filename,
        data: file.filebase64,
        directory: Directory.Documents,
      });

      //this.showAlert('Successfully downloaded file.', 'Download Success');
    } catch (err) {
      this.showAlert(err.message, 'Download Error');
    }

    try {
      // Get savedfileURI
      const file_uri = await Filesystem.getUri({
        directory: Directory.Documents,
        path: file.filename,
      });

      //previewAnyFile
      this.previewAnyFile
        .preview(file_uri.uri)
        .then((res: any) => console.log(res))
        .catch((error: any) => console.error(error));
    } catch (err) {
      this.showAlert(err.message, 'View Error');
    }
  }

  searchDepartmentMemo() {
    this.clickedSearch = true;
  }
  backClicked() {
    this._location.back();
  }
}
