import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashComponent } from './Screens/splash-screen/splash.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './Services/authentication.service';
import { IonicStorageModule } from '@ionic/storage';
import { AuthGuardService } from './Services/auth-guard.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DeclineEventModalModule } from './Components/decline-event-modal/decline-event-modal.module';
import { MemosModule } from './Screens/memos/memos.module';
import { DepartmentMemosModule } from './Screens/department-memos/department-memos.module';
import { PoliciesModule } from './Screens/policies/policies.module';
import { DepartmentPoliciesModule } from './Screens/department-policies/department-policies.module';
import { HeaderModule } from './Components/header/header.module';
import { LoadingPleaseWaitModule } from './Components/LoadingPleaseWait/LoadingPleaseWait.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { NgImageSliderModule } from 'ng-image-slider';
import { ViewContentModule } from './Components/ViewContent/ViewContent.module';
@NgModule({
  declarations: [AppComponent, SplashComponent],
  entryComponents: [],
  imports: [
    NgImageSliderModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    DeclineEventModalModule,
    DepartmentMemosModule,
    ViewContentModule,
    PoliciesModule,
    DepartmentPoliciesModule,
    HeaderModule,
    MemosModule,
    LoadingPleaseWaitModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    AuthGuardService,
    SplashScreen,
    StatusBar,
    AuthenticationService,
    PreviewAnyFile,
  ],
  exports: [BrowserAnimationsModule, HeaderModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
