import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-decline-event-modal',
  templateUrl: './decline-event-modal.component.html',
  styleUrls: ['./decline-event-modal.component.scss'],
})
export class DeclineEventModalComponent implements OnInit {
  result = '';
  constructor(
    public http: HttpClient,
    public dialog: MatDialogRef<DeclineEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    {
    }
  }

  ngOnInit() {}
  // submit(reason:any){
  //   console.log(reason);
  //   this.http
  //   .get<any>(
  //     'https://app.ubombosugar.com/api/statusAction?userId=5&state=denied&eventOrNewsId=5&comment='+reason
  //   )
  //   .subscribe(
  //     (data) => {
  //       console.log(data.success);
  //       if(data.success){
  //        document.getElementById('fifi').innerHTML ="<p>Reason submitted successfully</p>";
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
  // close(reason:any){
  //   this.dialog.close();
  // }
}
