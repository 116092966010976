import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './Authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(): boolean {
    return this.authenticationService.isAuthenticated();
  }
}
