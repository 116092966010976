import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Memos } from './memos.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HeaderModule } from '../../Components/header/header.module';
import { LoadingPleaseWaitModule } from '../../Components/LoadingPleaseWait/LoadingPleaseWait.module';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    HeaderModule,
    LoadingPleaseWaitModule,
  ],
  declarations: [Memos],
  exports: [Memos],
})
export class MemosModule {}
