import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MemosApis {
  memosData = new BehaviorSubject([]);
  constructor(
    public http: HttpClient,
    public rendererFactory: RendererFactory2
  ) {}
  ngOnInit() {}
  setMemosData(data: {}[]) {
    this.memosData.next(data);
  }
  getMemosData() {
    return this.memosData.value;
  }
  getMemos(token) {
    return this.http.get<any>(
      'https://app.ubombosugar.com/api/getNews',

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      }
    );
  }
}
