import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MoreMenuComponent } from './Components/more-menu/more-menu.component';
import { SplashComponent } from './Screens/splash-screen/splash.component';
import { EnterOtpComponent } from './Screens/enter-otp/enter-otp.component';
import { EnterNewPasswordComponent } from './Screens/enter-new-pawwsord/enter-new-password.component';
import { LanguageComponent } from './Components/language-preferences/language-preferences.component';
import { SupportComponent } from './Screens/support/support.component';
import { Memos } from './Screens/memos/memos.component';
import { PoliciesComponent } from './Screens/policies/policies.component';
import { AboutComponent } from './Screens/about/about.component';

import { DepartmentPoliciesComponent } from './Screens/department-policies/department-policies.component';
const routes: Routes = [
  {
    path: '',

    loadChildren: () =>
      import('./Screens/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login',

    loadChildren: () =>
      import('./Screens/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./TabNavigation/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'enterotp',
    component: EnterOtpComponent,
  },
  {
    path: 'enternewpass',
    component: EnterNewPasswordComponent,
  },
  {
    path: 'splashcreen',
    component: SplashComponent,
  },
  {
    path: 'language',
    component: LanguageComponent,
  },
  {
    path: 'Memo',
    component: Memos,
  },

  {
    path: 'About',
    component: AboutComponent,
  },
  {
    path: 'Support',
    component: SupportComponent,
  },
  {
    path: 'Policies',
    component: PoliciesComponent,
  },
  {
    path: 'Policies/DeptPolicy/:deptName',
    component: DepartmentPoliciesComponent,
  },
  /* {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, // {
  //   path: 'Memo/DeptMemo/:deptName',
  //   component: DepartmentMemosComponent,
  // },
  /* {
    path: 'more',
    component: MoreMenuComponent,
  }, */
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
