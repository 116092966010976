import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclineEventModalComponent } from './decline-event-modal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [DeclineEventModalComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class DeclineEventModalModule {}
