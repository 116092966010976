import { Component, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthenticationService } from './Services/authentication.service';
import { Storage } from '@ionic/storage';
import OneSignal from 'onesignal-cordova-plugin';
import { FcmService } from './services/fcm.service';
import { LoadingService } from './services/contentLoading.service';
import { NewsApis } from './Services/newsAPIs.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public getScreenWidth: any;
  isLoading = false;
  userId = 0;
  accessToken: string = '';

  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private storage: Storage,
    private statusBar: StatusBar,
    private auth: AuthenticationService,
    private fcmService: FcmService,
    public appLoading: LoadingService,
    public newsAPIS: NewsApis
  ) {
    this.appLoading.setIsLoading(true);
    this.initializeApp();
    /*  OneSignalInit();  */
    this.appLoading.setIsLoading(false);
  }
  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    console.log('window:resize', this.getScreenWidth);
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleBlackOpaque();
      this.splashScreen.hide();
      this.ifLoggedIn();
      // Trigger the push setup
      this.fcmService.initPush();
    });
  }

  ifLoggedIn() {
    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.storage.get('ACCESS_TOKEN').then((res) => {
          if (res) {
            this.accessToken = res;
          }
        });
        this.storage.get('User_Info').then((res) => {
          if (res) {
            this.userId = res.id;
            this.newsAPIS
              .getNews(res.id, this.accessToken)
              .subscribe((news) => {
                this.newsAPIS.setNewsData(news.data);
              });
          }
        });
        this.router.navigate(['tabs']);
      } else {
        this.router.navigate(['login']);
      }
    });
  }
}
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE
  OneSignal.setLogLevel(6, 0);

  // NOTE: Update the setAppId value below with your OneSignal AppId.
  OneSignal.setAppId('f4346415-59a6-48e6-a4b8-cfac6d252a4f');
  OneSignal.setNotificationOpenedHandler(function (jsonData) {});

  // iOS - Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {});
}
