import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Storage } from '@ionic/storage';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NoticesApis {
  noticesData = new BehaviorSubject([]);
  accessToken: string = '';
  constructor(
    public http: HttpClient,
    private storage: Storage,
    public rendererFactory: RendererFactory2
  ) {}
  ngOnInit() {}
  setNoticesData(data: {}[]) {
    this.noticesData.next(data);
  }
  getNoticesData() {
    return this.noticesData.value;
  }

  getNotices(accessToken) {
    return this.http.get<any>('https://app.ubombosugar.com/api/getNotices', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      }),
    });
  }
}
