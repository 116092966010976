import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NewsApis {
  newsData = new BehaviorSubject([]);
  memosData = new BehaviorSubject([]);
  accessToken: string = '';
  // Http Options
  httpOptions = {};
  userId: any;

  constructor(
    public http: HttpClient,
    public rendererFactory: RendererFactory2,
    private storage: Storage
  ) {}
  ngOnInit() {
    //let token = JSON.parse(sessionStorage.getItem('ACCESS_TOKEN')!);
  }
  setNewsData(data: {}[]) {
    this.newsData.next(data);
  }
  getNewsData() {
    return this.newsData.value;
  }
  setMemosData(data: {}[]) {
    this.memosData.next(data);
  }
  getMemosData() {
    return this.memosData.value;
  }

  getNews(userId, token): Observable<any> {
    return this.http.get('https://app.ubombosugar.com/api/getNews');
  }
  // getNews(userId, token) {
  //   /* return this.http.get<any>(
  //     'https://app.ubombosugar.com/api/events?type=news&userId=' + userId,
  //     {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + token,
  //       }),
  //     }
  //   ); */
  //   return this.http.get<any>('https://app.ubombosugar.com/api/getNews', {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + token,
  //     }),
  //   });
  // }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    console.log(message);
  }
}
