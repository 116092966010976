/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  throwError,
  BehaviorSubject,
  observable,
  Subscription,
} from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { LoadingService } from '../services/contentLoading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Http Options
  public readonly tokenName: string = 'token';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  authState = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private appLoading: LoadingService
  ) {
    // this.platform.ready().then(() => {
    //   this.ifLoggedIn();
    // });
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An Error Occoured: ', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  ifLoggedIn() {
    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.authState.next(true);
      }
    });
  }

  login(credentials: { cellPhone; password }): Observable<any> {
    return this.http
      .post<any>(
        environment.baseURI + '/login',
        JSON.stringify(credentials),
        this.httpOptions
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  logout() {
    this.appLoading.setIsLoading(true);
    this.storage.remove('User_Info').then(() => {
      this.router.navigate(['login']);
      this.authState.next(false);
    });
    this.appLoading.setIsLoading(false);
  }

  isAuthenticated() {
    return this.authState.value;
  }
}
