import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isLoading = new BehaviorSubject(false);
  constructor() {}

  IsContentLoading() {
    return this.isLoading.value;
  }
  setIsLoading(data: boolean) {
    this.isLoading.next(data);
  }
}
