import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DepartmentPoliciesComponent } from './department-policies.component';
import { HeaderComponent } from '../../Components/header/header.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingPleaseWaitModule } from '../../Components/LoadingPleaseWait/LoadingPleaseWait.module';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    LoadingPleaseWaitModule,
  ],
  declarations: [DepartmentPoliciesComponent],
  exports: [DepartmentPoliciesComponent],
})
export class DepartmentPoliciesModule {}
