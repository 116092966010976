/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Inject,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommentsComponent } from '../comments/comments.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { NewsApis } from '../../Services/newsAPIs.service';

@Component({
  selector: 'app-ViewContent',
  templateUrl: './ViewContent.component.html',
  styleUrls: ['./ViewContent.component.scss'],
})
export class ViewContentComponent implements OnInit {
  newsObject: any;
  NewsObject: any;
  noLikes: any;
  liked: any;
  archived: any;
  newsData: any;
  accessToken: string;
  userId = 0;
  isLoading = true;
  videoClicked = false;
  likeAction: Function = () => {};
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ViewContentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public content,
    public http: HttpClient,
    private renderer: Renderer2,
    private storage: Storage,
    public newsAPIS: NewsApis
  ) {
    /* console.log(content, 'data');
    console.log('ayyy'); */
    this.newsObject = content;
  }
  ngOnInit() {
    window.addEventListener('keyup', disableF5);
    window.addEventListener('keydown', disableF5);

    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) e.preventDefault();
    }
    this.storage.get('ACCESS_TOKEN').then((res) => {
      if (res) {
        this.accessToken = res;
      }
    });
    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.userId = res.id;
        this.newsAPIS.getNews(res.id, this.accessToken).subscribe((news) => {
          var NewsArray: any = [];
          news.forEach((element) => {
            if (element.type === 'news') {
              element.description = JSON.parse(element.description)

              NewsArray.push(element);
            }
          });

          NewsArray.forEach((element) => {
            var link =
              'https://app.ubombosugar.com/api/comment?id=' + element.id;
            this.http
              .get<any>(link, {
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ',
                }),
              })
              .subscribe(
                (data) => {
                  // this.getcomments(element.id);
                  element.noLikes = data.likes;

                  console.log('likes:', element.noLikes);
                },
                (error) => {
                  console.log(error);
                }
              );
          });
          this.newsAPIS.setNewsData(news.data);
        });
      }
    });
  }

  onCommentClick(id) {
    /* console.log('texting'); */

    /*  this.dialogRef.close(); */
    const dialogRef = this.dialog.open(CommentsComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      closeOnNavigation: true,
    });
    dialogRef.componentInstance.commentId = id;
    /* dialogRef.componentInstance.close = this.dialogRef.close(); */
    /* */
    dialogRef.afterClosed().subscribe((result) => {
      console.log('closed'); // Pizza!
    });
  }
  favoriteActionView(favid) {
    this.isLoading = true;
    const userId = this.userId;
    const id = 'fav' + favid;
    const ids = 'faview' + favid;
    const btn = id + 'btn';
    const fav = document.getElementById(ids);
    const favLbl = document.getElementById(ids + 'lbl');
    const el = fav.getAttribute('name');
    let newstate = '';
    let link = '';

    if (el === 'bookmark') {
      console.log('imm');
      newstate = '0';
      this.renderer.setProperty(fav, 'name', 'bookmark-outline');
      this.renderer.setProperty(favLbl, 'innerHTML', 'Archive');
    } else {
      console.log('imm in');
      newstate = '1';
      this.renderer.setProperty(fav, 'name', 'bookmark');
      this.renderer.setProperty(favLbl, 'innerHTML', 'Remove');
    }
    link =
      'https://app.ubombosugar.com/api/statusAction?userId=' +
      userId +
      '&eventOrNewsId=' +
      favid +
      '&archive=' +
      newstate;

    this.http
      .get<any>(link, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: 'Bearer ' + this.accessToken,
        }),
      })
      .subscribe(
        (data) => {
          this.storage.get('User_Info').then((res) => {
            if (res) {
              this.userId = res.id;
              this.newsAPIS
                .getNews(res.id, this.accessToken)
                .subscribe((news) => {
                  this.newsAPIS.setNewsData(news.data);
                  this.isLoading = false;
                });
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  likeActions(likeid) {
    const id = 'likes' + likeid;

    const like = document.getElementById(id);

    const el = like.getAttribute('name');
    let newstate = '';
    let link = '';

    if (el === 'heart') {
      newstate = '0';
      this.renderer.setProperty(like, 'name', 'heart-outline');
    } else {
      newstate = '1';
      this.renderer.setProperty(like, 'name', 'heart');
    }
    this.storage.get('ACCESS_TOKEN').then((res) => {
      link =
        'https://app.ubombosugar.com/api/statusAction?userId=' +
        this.userId +
        '&eventOrNewsId=' +
        likeid +
        '&like=' +
        newstate;
      this.http
        .get<any>(link, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + res,
          }),
        })
        .subscribe(
          (data) => {},
          (error) => {
            console.log(error);
          }
        );
    });
  }
  close() {
    console.log(this.newsAPIS.getNewsData(),"sdsds");
    this.dialogRef.componentInstance.close();
  }
  startVideo(): void {
    this.videoClicked = true;
    this.videoPlayer.nativeElement.play();
  }
}
