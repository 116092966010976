import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepartmentMemosComponent } from '../department-memos/department-memos.component';
import { HeaderModule } from '../../Components/header/header.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    HeaderModule,
  ],
  declarations: [DepartmentMemosComponent],
  exports: [DepartmentMemosComponent],
})
export class DepartmentMemosModule {}
