import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageComponent } from '../language-preferences/language-preferences.component';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../Services/authentication.service';
import { Storage } from '@ionic/storage';
import { LoadingService } from '../../services/contentLoading.service';
import { NewsApis } from 'src/app/Services/newsAPIs.service';
import { NoticesApis } from 'src/app/Services/noticesAPIS.service';
import { MemosApis } from 'src/app/Services/memoAPIS.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  headerTitle = '';
  @Input()
  backButtonVisible = 'true';
  isLoading = true;
  likedData: any = [];
  user: any = {
    id: 0,
    name: '',
    email: '',
    email_verified_at: null,
    created_at: '',
    updated_at: '',
    language: '',
    profilePic: null,
  };
  isLoggingIn = false;
  userId = 0;
  accessToken: string = '';
  departments: any;
  commentData: any = [];
  isThereCommentsData = false;
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private _location: Location,
    private auth: AuthenticationService,
    private storage: Storage,
    private router: Router,
    private appLoading: LoadingService,
    private newsServices: NewsApis,
    private noticesServices: NoticesApis,
    private memosServices: MemosApis,
    private renderer: Renderer2
  ) {
    this.getUser();
  }

  ngOnInit() {}
  openLangPrefDialog() {
    const dialogRef = this.dialog.open(LanguageComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }
  backClicked() {
    this._location.back();
  }

  logout() {
    this.auth.logout();
  }

  getUser() {
    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.user = res;
      }
    });
  }
  navigateto(PageTitle) {
    this.router.navigate([PageTitle]);
  }

  refreshAppContent() {
    this.appLoading.setIsLoading(true);

    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.userId = res.id;
        this.newsServices
          .getNews(res.id, this.accessToken)
          .subscribe((news) => {
            console.log(news);
            var NewsArray: any = [];
            news.forEach((element) => {
              if (element.type === 'news') {
                NewsArray.push(element);
              }
            });

            NewsArray.forEach((element) => {
              var link =
                'https://app.ubombosugar.com/api/comment?id=' + element.id;
              this.http
                .get<any>(link, {
                  headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ',
                  }),
                })
                .subscribe(
                  (data) => {
                    //this.getcomments(element.id);
                    element.noLikes = data.likes;
                    //element.newsId = data.id;
                    console.log('news IDs:', element.id);
                    //console.log('likes:', element.noLikes);
                    /////start likes
                    //this.getLikedPosts(res.id, element.id);
                    const link =
                      'https://app.ubombosugar.com/api/likedByUser?userId=' +
                      res.id +
                      '&postId=' +
                      element.id;
                    this.http
                      .get<any>(link, {
                        headers: new HttpHeaders({
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ',
                        }),
                      })
                      .subscribe(
                        (data) => {
                          this.likedData = data;
                          element.like = this.likedData.like;
                          element.archive = this.likedData.archive;
                          console.log('Liked data', data);
                          console.log('liked==', element.like);
                          console.log('archived==', element.archive);

                          // if(element.like=="1"){
                          //   this.renderer.setProperty('name', 'heart');
                          // }else{
                          //   this.renderer.setProperty('name', 'heart');
                          // }
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                    /////End Likes
                    console.log('likes:', res.id, '===', element.id);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
              const link2 =
                'https://app.ubombosugar.com/api/comment?id=' + element.id;
              this.http
                .get<any>(link2, {
                  headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ',
                  }),
                })
                .subscribe(
                  (data) => {
                    if (data.comments.length) {
                      this.isThereCommentsData = true;
                    }
                    this.commentData = data;

                    element.noComments = data.comments.length;
                    //this.commentsDataArray.push(this.noComments);

                    console.log('Array LengthS ', element.noComments);

                    console.log('Comments', this.commentData);
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            });
            this.newsServices.setNewsData(NewsArray);
          });
        this.noticesServices.getNotices(res).subscribe((notices) => {
          this.noticesServices.setNoticesData(notices.data);
        });
        this.memosServices.getMemos(res).subscribe((memos) => {
          console.log(memos);
          var MemosArray: any = [];
          memos.forEach((element) => {
            if (element.type === 'memo') {
              
              element.description = JSON.parse(element.description)

              MemosArray.push(element);
            }
          });
          console.log(MemosArray);
          this.memosServices.setMemosData(MemosArray);
        });
      }
    });
    this.appLoading.setIsLoading(false);
  }
}
