import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-enter-otp',
  templateUrl: './enter-otp.component.html',
  styleUrls: ['./enter-otp.component.scss'],
})
export class EnterOtpComponent implements OnInit {
  cell = 0;
  constructor(
    private router: Router,
    public http: HttpClient,
    private renderer: Renderer2,
    private storage: Storage
  ) {}

  ngOnInit() {
    this.storage.get('cell').then((res) => {
      if (res) {
        this.cell = res;
      }
    });
  }

  podtOtp(oTp: string) {
    const output = document.getElementById('outputs');
    const link = 'https://app.ubombosugar.com/api/checkOtp';
    this.http
      .post<any>(link, {
        otp: oTp,
        cellNo: this.cell,
      })
      .subscribe(
        (data) => {
          if (data.message === 'OTP is valid') {
            this.router.navigate(['enternewpass']);
          } else {
            this.renderer.setProperty(output, 'innerHTML', data.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  navigateToLogin() {}

  resendOTP() {}
}
