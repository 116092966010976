import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-department-memos',
  templateUrl: './department-memos.component.html',
  styleUrls: ['./department-memos.component.scss'],
})
export class DepartmentMemosComponent implements OnInit {
  departmentName: string = '';
  search: string = '';
  clickedSearch = true;
  memos: any;
  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    public http: HttpClient
  ) {
    this.departmentName = this.route.snapshot.paramMap.get('deptName');
  }

  ngOnInit() {
    this.getMemos();
  }
  getMemos() {
    this.http
      .get<any>(
        'https://app.ubombosugar.com/api/getMemos?dapartment=' +
          this.departmentName
      )
      .subscribe(
        (data) => {
          this.memos = data.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  DownloadMemo(title) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http
      .get('https://app.ubombosugar.com/docs/' + 'access doc.pdf', {
        headers: headers,
      })
      .subscribe((data) => {
      });
  }

  searchDepartmentMemo() {
    this.clickedSearch = true;
  }
  backClicked() {
    this._location.back();
  }
}
