import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PoliciesApis {
  policiesData = new BehaviorSubject([]);

  constructor(public http: HttpClient) {}
  setPoliciesData(data: {}[]) {
    this.policiesData.next(data);
  }
  getPoliciesData() {
    return this.policiesData.value;
  }
  getDepartments(token) {
    return this.http.get<any>(
      'https://app.ubombosugar.com/api/getDepartments',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      }
    );
  }
  getAllPolicies(departmentName, token) {
    return this.http.get<any>(
      'https://app.ubombosugar.com/api/getPolicies?dapartment=' +
        departmentName,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      }
    );
  }
}
