/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  Inject,
  Renderer2,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommentsModel } from '../comments/comments.model';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Storage } from '@ionic/storage';
import { Comment } from './comment.model';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsComponent implements OnInit {
  @ViewChild('userComment') userComment;
  @ViewChild('commentsContainer', { static: false }) div: ElementRef;
  replyingToIsVisible = true;
  commentId: string;
  // public commentsArray: Comments2Model;
  commentData: any = [];
  noComments: any;
  isThereCommentsData = false;
  LoggedInUserID;
  commentToEdit: Comment = {
    archive: 'scs',
    comment: 'Testing',
    created_at: '2021-12-14T13:02:59.000000Z',
    description: '<p>App is available</p>',
    eventsId: 38,
    ne_id: 38,
    image: '1642672068.JPG',
    liked: '0',
    name: 'hooks',
    postDate: '',
    postTime: '',
    profilePic: '',
    published_by: '1',
    schedule: '',
    schedule_date: '2021-12-14',
    schedule_time: '15:01',
    state: '',
    title: 'New Ubombo Mobile App Developed By KTO',
    type: 'news',
    updated_at: '2022-01-21T08:50:04.000000Z',
    userId: 6,
  };
  isEditing: boolean = false;
  editedText = '';
  isDeleting: boolean = false;
  accessToken: string = '';
  DeleteFunction: any;
  comments: any;
  constructor(
    public http: HttpClient,
    private el: ElementRef,
    public dialog: MatDialog,
    private storage: Storage,
    private platform: Platform,
    private renderer: Renderer2
  ) {}
  ngOnInit() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.dialog.closeAll();
    });
    this.storage.get('User_Info').then((user) => {
      this.LoggedInUserID = user.id;
      this.storage.get('ACCESS_TOKEN').then((res) => {
        if (res) {
          this.accessToken = res;

          this.getcomments(this.commentId, res);
        }
      });
    });
  }

  onEditedText(editedText): void {
    this.editedText = editedText;
  }
  editComment(comment) {
    this.commentToEdit = comment;
    this.isEditing = true;
  }
  deleteComment(comment) {
    this.isDeleting = true;
    const dialogRef = this.dialog.open(DeleteCommentComponent, {
      width: '250px',
      data: comment.ne_id,
      panelClass: 'delete-modalbox',
      /* deleteComment: this.deleteComment(), */
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'Closed') {
        this.isEditing = false;
        /* this.commentData.comments =   this.commentData.comments.filter((data) => data.ne_id !== comment.ne_id) */

        this.ngOnInit();
      }
    });
  }
  close() {
    if (this.isEditing == false) {
      this.dialog.closeAll();
    } else this.isEditing = false;
  }
  comment() {
    if (this.isEditing == false) {
      this.storage.get('User_Info').then((res) => {
        if (res) {
          const comment = this.userComment.nativeElement.value;
          this.userComment.nativeElement.value = ' ';
          const link = 'https://app.ubombosugar.com/api/postStatusAction';
          this.http
            .post<any>(
              link,
              {
                userId: this.LoggedInUserID,
                type: 'news',
                eventOrNewsId: this.commentId,
                comment: comment,
              },
              {
                headers: new HttpHeaders({
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.accessToken,
                }),
              }
            )
            .subscribe(
              (data) => {
                if (data.success) {
                  this.commentData.comments.push({
                    name: res.name,
                    userId: this.LoggedInUserID,
                    comment: comment,
                    ne_id: data.newCommentId,
                  });
                }
                this.isEditing = false;
              },
              (error) => {
                console.log(error);
              }
            );
        }
      });
    }
  }

  onEditClick() {
    if (this.isEditing == true) {
      const link = `https://app.ubombosugar.com/api/updatecomment`;
      this.http
        .post<any>(
          link,
          {
            userId: this.commentToEdit.userId,
            commnetid: this.commentToEdit.ne_id,
            comment: this.editedText,
          },
          {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.accessToken,
            }),
          }
        )
        .subscribe(
          (data) => {
            this.storage.get('ACCESS_TOKEN').then((res) => {
              if (res) {
                this.getcomments(this.commentId, res);
                console.log(
                  'comments===',
                  this.getcomments(this.commentId, res)
                );
              }
            });
            this.isEditing = false;
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  getcomments(id, token) {
    const link = 'https://app.ubombosugar.com/api/comment?id=' + id;
    this.http
      .get<any>(link, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      })
      .subscribe(
        (data) => {
          if (data.comments.length) {
            this.isThereCommentsData = true;
          }
          this.commentData = data;
          console.log('Comments', data.comments.length);
          this.noComments = data.comments.length;

          //display
          // Array.push(this.noComments);

          console.log('Comments', data);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  styleUrls: ['./comments.component.scss'],
  template: `
    <div class="content">
      <h1>Delete comment</h1>
      <p>Are you sure you want to delete your comment?</p>
      <div
        style="display:flex;flex-direction:row;justify-content:space-between"
      >
        <div
          style="
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width:100%
      "
        >
          <label
            (click)="onNoClick()"
            style="color: white; font-weight: bold; align-self: center;width:25%;
            background-color: #007a3e;text-align: center;
            "
            >No</label
          >
          <label
            (click)="onYesClick()"
            style="color: white; font-weight: bold; align-self: center;width:25%;background-color: #007a3e;text-align: center;"
            >Yes</label
          >
        </div>
      </div>
    </div>
  `,
})
export class DeleteCommentComponent {
  isEditing: boolean = false;
  commentId: string;
  commentData: CommentsModel = {
    comments: [
      {
        id: 17,
        eventsId: 17,
        userId: 1,
        comment: 'loading...',
        state: null,
        liked: '1',
        archive: null,
        updated_at: '2021-11-04T14:52:52.000000Z',
        created_at: '2021-11-04T08:01:09.000000Z',
        title: 'test',
        image: '1636021407.png',
        description: '"<p>lorem</p>"',
        type: 'event',
        postDate: '2021-11-04',
        postTime: '11:04:25',
        published_by: '5',
        schedule_date: '2021-11-04',
        schedule_time: '08:00',
        schedule: null,
        name: '',
        noLikes: 0,
        noComments: 0,
      },
    ],
    success: true,
    likes: 0,
    noComments: 0,
  };
  accessToken: string = '';

  constructor(
    public dialogRef: MatDialogRef<DeleteCommentComponent>,
    public http: HttpClient,
    public storage: Storage,
    @Inject(MAT_DIALOG_DATA) public commentID: DialogData
  ) {
    this.storage.get('ACCESS_TOKEN').then((res) => {
      this.accessToken = res;
    });
  }

  onYesClick(): void {
    this.storage.get('User_Info').then((user) => {
      this.http
        .post<any>(
          `https://app.ubombosugar.com/api/deletecomment`,
          {
            userId: user.id,
            commnetid: this.commentID,
          },
          {
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.accessToken,
            }),
          }
        )
        .subscribe(
          (data) => {},
          (error) => {
            console.log(error);
          }
        );
    });
    this.dialogRef.close('Closed');
  }
  onNoClick(): void {
    this.dialogRef.close('Closed');
  }
}
