import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { MemosApis } from '../../Services/memoAPIS.service';
import { LoadingService } from '../../Services/contentLoading.service';
import { Storage } from '@ionic/storage';
//import { Browser } from '@capacitor/browser';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewsApis } from '../../Services/newsAPIs.service';
import { CommentsComponent } from '../../Components/comments/comments.component';
import { ViewContentComponent } from '../../Components/ViewContent/VIewContent.component';
@Component({
  selector: 'app-memos',
  templateUrl: './memos.component.html',
  styleUrls: ['./memos.component.scss'],
})
export class Memos implements OnInit {
  isFavourite: boolean = false;
  isLiked: boolean = true;
  NewsData: any;
  userId = 0;
  loadingStyle: object = {};
  accessToken: string = '';
  isLoading = true;
  NewsObject: any;
  userIsViewingContent = false;
  regex = /(<([^>]+)>)/gi;
  isArchiving = false;
  newsObject: any = null;

  videoClicked = false;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
    private renderer: Renderer2,
    private storage: Storage,
    public newsAPIS: NewsApis
  ) {}
  ngOnInit() {
    this.isLoading = true;
    this.storage.get('ACCESS_TOKEN').then((res) => {
      if (res) {
        this.accessToken = res;
      }
    });
    this.storage.get('User_Info').then((res) => {
      if (res) {
        this.userId = res.id;
        this.newsAPIS.getNews(res.id, this.accessToken).subscribe((memos) => {
          var MemosArray: any = [];
          memos.forEach((element) => {
            if (element.type === 'memo') {
              element.description = JSON.parse(element.description);
              console.log(element.description);

              MemosArray.push(element);
            }
          });
          this.newsAPIS.setMemosData(MemosArray);
        });
        this.isLoading = false;
      }
    });
  }
  onFavouriteClick(isFavourted) {
    this.isFavourite = isFavourted;
  }
  onLikeClick(isLiked) {
    this.isLiked = isLiked;
  }
  startVideo(): void {
    this.videoClicked = true;
    this.videoPlayer.nativeElement.play();
  }
  closeItem() {
    this.newsObject = null;
    this.ngOnInit();
  }
  onCommentClick(id) {
    const dialogRef = this.dialog.open(CommentsComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      closeOnNavigation: true,
      data: {
        commentID: id,
      },
    });
    dialogRef.componentInstance.commentId = id;
  }
  viewContent(content) {
    const ViewContentdialog = this.dialog.open(ViewContentComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      data: content,
    });
    ViewContentdialog.componentInstance.content = content;
  }
  viewItem(element: any) {
    console.log(element);
    this.newsAPIS.setMemosData([]);
    this.newsObject = element;
  }
  goBack() {
    this.userIsViewingContent = false;
    this.NewsObject = {};
  }
  likeActions(likeid) {
    const id = 'like' + likeid;
    const like = document.getElementById(id);

    const el = like.getAttribute('name');
    let newstate = '';
    let link = '';
    if (el === 'heart') {
      newstate = '0';
      this.renderer.setProperty(like, 'name', 'heart-outline');
    } else {
      newstate = '1';
      this.renderer.setProperty(like, 'name', 'heart');
    }
    this.storage.get('ACCESS_TOKEN').then((res) => {
      link =
        'https://app.ubombosugar.com/api/statusAction?userId=' +
        this.userId +
        '&eventOrNewsId=' +
        likeid +
        '&like=' +
        newstate;
      this.http
        .get<any>(link, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + res,
          }),
        })
        .subscribe(
          (data) => {},
          (error) => {
            console.log(error);
          }
        );
    });
  }

  favoriteAction(favid) {
    this.isLoading = true;
    const userId = this.userId;
    const id = 'fav' + favid;
    const ids = 'faview' + favid;
    const btn = id + 'btn';
    const fav = document.getElementById(id);
    const favLbl = document.getElementById(id + 'lbl');
    const el = fav.getAttribute('name');
    let newstate = '';
    let link = '';

    if (el === 'bookmark') {
      newstate = '0';
      this.renderer.setProperty(fav, 'name', 'bookmark-outline');
      this.renderer.setProperty(fav, 'innerHTML', 'Archive');
    } else {
      newstate = '1';
      this.renderer.setProperty(fav, 'name', 'bookmark');
      this.renderer.setProperty(fav, 'innerHTML', 'Remove');
    }
    link =
      'https://app.ubombosugar.com/api/statusAction?userId=' +
      userId +
      '&eventOrNewsId=' +
      favid +
      '&archive=' +
      newstate;
    this.http
      .get<any>(link, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: 'Bearer ' + this.accessToken,
        }),
      })
      .subscribe(
        (data) => {
          this.storage.get('User_Info').then((res) => {
            if (res) {
              this.userId = res.id;
              this.newsAPIS
                .getNews(res.id, this.accessToken)
                .subscribe((news) => {
                  this.newsAPIS.setNewsData(news.data);
                  this.isLoading = false;
                });
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onScroll(event) {
    if (event.detail.deltaY > 0) {
    } else if (event.detail.deltaY < 0) {
    }
  }
  favoriteActionView(favid) {
    const userId = this.userId;
    const id = 'faview' + favid;
    const btn = id + 'btn';
    const fav = document.getElementById(id);
    const favLbl = document.getElementById(id + 'lbl');
    this.favoriteAction(favid);
    const el = fav.getAttribute('name');
    let newstate = '';
    let link = '';

    if (el === 'bookmark') {
      newstate = '0';
      this.renderer.setProperty(fav, 'name', 'bookmark-outline');
      this.renderer.setProperty(favLbl, 'innerHTML', 'Archive');
    } else {
      newstate = '1';
      this.renderer.setProperty(fav, 'name', 'bookmark');
      this.renderer.setProperty(favLbl, 'innerHTML', 'Remove');
    }
    link =
      'https://app.ubombosugar.com/api/statusAction?userId=' +
      userId +
      '&eventOrNewsId=' +
      favid +
      '&archive=' +
      newstate;
    this.http
      .get<any>(link, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: 'Bearer ' + this.accessToken,
        }),
      })
      .subscribe(
        (data) => {},
        (error) => {
          console.log(error);
        }
      );
  }
}
