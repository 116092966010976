import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoliciesComponent } from './policies.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../Components/header/header.component';
@NgModule({
  declarations: [PoliciesComponent],
  imports: [
    IonicModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    RouterModule,
    CommonModule,
  ],
  exports: [PoliciesComponent],
})
export class PoliciesModule {}
