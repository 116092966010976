import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommentsModule } from '../comments/comments.module';
import { ViewContentComponent } from './ViewContent.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { LoadingPleaseWaitModule } from '../LoadingPleaseWait/LoadingPleaseWait.module';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    IonicModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    CommentsModule,
    LoadingPleaseWaitModule,
  ],
  declarations: [ViewContentComponent],
  exports: [ViewContentComponent,CommonModule],
})
export class ViewContentModule {}
