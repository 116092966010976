import { Injectable } from '@angular/core';
import {
  Plugins,
  // PushNotification,
  // PushNotificationToken,
  // PushNotificationActionPerformed,
  Capacitor,
} from '@capacitor/core';
import {
  PushNotificationActionPerformed,
  PushNotification,
  PushNotificationToken,
  ActionPerformed,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
const { PushNotifications } = Plugins;
@Injectable({
  providedIn: 'root',
})
export class FcmService {
  constructor(private router: Router) {}
  initPush() {
    if (Capacitor.platform !== 'web') {
      this.registerPush();
    }
  }
  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const data = notification.notification.data;
       
        if (data.detailsId) {
          this.router.navigateByUrl(`/home/${data.detailsId}`);
        }
      }
    );
  }
}
